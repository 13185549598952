// require("./chunk/_polyfills.js");

import { viewport, debounce } from "./chunk/_utils.js";
import filter from "./chunk/filter.js";
filter(false)

/**
 * scroll_smooth
 */
import scroll_smooth from "./chunk/scroll_smooth.js";
// scroll_smooth()


/**
 * scriptStateHandler
 * @param {boolean} state should the animatioin run?
 */
import scrollspy from "./chunk/scroll_spy.js";

const scrollspyElements = document.querySelectorAll(".isAnchor[id]");

let scrollspyHandler = (state) => {
  // const isBigScreen = window.innerWidth > 992 ? true : false;
  scrollspy(scrollspyElements);
};

/**
 * throtteld restart of scriptStateHandler()
 * on viewport changes
 */
let debounceAnimationScripts = debounce(
  function (state = true) {
    scrollspyHandler();
  },
  300,
  false
);
window.addEventListener("resize", debounceAnimationScripts);
window.addEventListener("orientationchange", debounceAnimationScripts);


if (document.readyState == "complete") {
  scrollspyHandler();
  // docReady()
} else {
  document.onreadystatechange = function () {
    scrollspyHandler();
    // docReady()
  };
}










/**
 * MDC Drawer
 *
 */
const drawerElement = document.querySelector('.mdc-drawer');
import {MDCDrawer} from "@material/drawer";

const drawer = MDCDrawer.attachTo(drawerElement);
const listEl = document.querySelectorAll('.mdc-drawer a');
listEl.forEach(link => {
  link.addEventListener('click', (event) => {
    drawer.open = false;
  });
});

const burger = document.querySelector('.burger');
burger.addEventListener('click', (event) =>  {
  drawer.open = !drawer.open;
});

/**
 * MDC TopAppBar
 *
 */
// const topAppBarElement = document.querySelector('#header');
// import { MDCTopAppBar } from '@material/top-app-bar';
// import { numbers } from '@material/top-app-bar/constants';

// numbers.MAX_TOP_APP_BAR_HEIGHT = 140;


// const topAppBar = new MDCTopAppBar(topAppBarElement);




/**
 * MDC Chips
 *
 */

import {MDCChipSet} from '@material/chips';
const chipSetEl = document.querySelector('.mdc-chip-set');
if (chipSetEl) {
  const chipSet = new MDCChipSet(chipSetEl);
  const chip1 = document.querySelector('#mdc-chip-1')
  
  chipSet.listen('MDCChip:interaction', function(event) {
    event.preventDefault();
    
    if (chipSet.selectedChipIds.length === 0 || chipSet.selectedChipIds.includes("mdc-chip-1") ) {
      chipSet.chips[0].setSelectedFromChipSet(true)
      filter(false)
    } else {
      chipSet.chips[0].setSelectedFromChipSet(false)
      filter(event.target)
    }
  });
}



/**
 * MDC Ripple
 *
 */
import {MDCRipple} from '@material/ripple';
const button = document.querySelector('.mdc-button');
if (button) {
  const buttonRipple = new MDCRipple(document.querySelector('.mdc-button'));
}

/**
 * MDC Fab
 * https://m2.material.io/components/buttons-floating-action-button/web#using-fabs
 */
const mdcTextFieldsAll = document.querySelectorAll('.mdc-fab');
if (mdcTextFieldsAll) {
  [...mdcTextFieldsAll].forEach(element => {
    const fabRipple = new MDCRipple(element);
  });
}
// const fabRipple = new MDCRipple(document.querySelector('.mdc-fab'));

/**
 * MDC Form
 *
 */
import {MDCTextField} from '@material/textfield';
import flatpickr from "flatpickr"; /* https://flatpickr.js.org/examples/#selecting-multiple-dates */

const mdcTextFieldEl = document.querySelectorAll('.mdc-text-field');
if (mdcTextFieldEl) {
  [...mdcTextFieldEl].forEach(element => {
    const textField = new MDCTextField(element);

    if (element.control.id === "form-input-date") {
      const flatpickrOptions = {
        // inline: true,
        mode: 'multiple',
        minDate: 'today',
        dateFormat: 'd-m-Y'
      }
      flatpickr("#form-input-date", flatpickrOptions);      
    }
    
  });
}





/** 
 * Video
 */
import * as basicLightbox from 'basiclightbox'
const videos = document.querySelectorAll('a[data-video-id]');
let instance;

[...videos].forEach(openModal);

function openModal (video) {
  const youTubeId = video.getAttribute('data-video-id');
  
  video.onclick = (e) => {
    e.preventDefault();
    instance = basicLightbox.create(`
    <div class="modal">
      <div class="youtube_wrapper">
        <iframe src="https://www.youtube-nocookie.com/embed/${youTubeId}?controls=1&nocookie=true&autoplay=1&modestbranding=1&playsinline=0"  frameborder="0"></iframe>
      </div>
    </div>  
    `)
    instance.show()
  }
}

//detect Escape key press
document.addEventListener("keydown", function(event) {
  if(event.keyCode === 27 && instance ){
    instance.close()
 }
});






/** 
 * Flickity
 */
import Flickity from 'flickity';

const carousel = document.querySelector(".carousel");
const corouselOptions = {
  // options
  // cellAlign: 'left',
  // imagesLoaded: true,
  percentPosition: false,
  // setGallerySize: false,
  prevNextButtons: true,
  pageDots: false,
  wrapAround: true, // infinite

  autoPlay: 6000,
  pauseAutoPlayOnHover: false,

  adaptiveHeight: true,
  arrowShape: { 
    x0: 25,
    x1: 60, y1: 50,
    x2: 65, y2: 50,
    x3: 30
  }
}

if ( carousel ) {
  const flkty = new Flickity(carousel, corouselOptions);
}

/** 
 * Glightbox
 * https://biati-digital.github.io/glightbox/
 */

import GLightbox from 'glightbox';
const ligtboxOptions = {
  touchNavigation: true,
  loop: true,
  zoomable: true,
  autoplayVideos: true
}
const lightbox = GLightbox({ ...ligtboxOptions });