import { log, viewport, debounce } from "./_utils.js";

export default (el) => {
  return init(el);
};




/**
 * Smooth Scrolling
 * See https://www.html5rocks.com/en/tutorials/speed/animations/
 * See https://developer.mozilla.org/en-US/docs/Web/Events/scroll
 * See https://www.kirupa.com/html5/smooth_parallax_scrolling.htm
 */
const path = window.location.pathname;
const linkObj = [];
const spyObj = [];
let firstSectionId;
let lastScrollY = 0;
let ticking = false;
let linkTop = document.querySelector("#scrollTop a");


/**
 * init variables / actions
 * build spyObject if empty or update if not empty
 */
let init = (el) => {

  if ( el.length == 0 ) {
    // console.log('keine Anker Elemente vorhanden');
    return
  }

  lastScrollY = window.scrollY;
  if (spyObj.length === 0) {
    el.forEach((param) => {
      // find all links, that match to the targets
      const links = document.querySelectorAll(`a[href="${path}#${param.id}"]`);

      spyObj.push({
        // id: param.id,
        node: param,
        offsetTop: param.offsetTop,
        offsetTopEnd: param.offsetTop + param.scrollHeight,
        links: links,
        linkState: false,
      });
      linkObj.push(links);
    });
  } else {
    el.forEach((param, i) => {
      spyObj[i].offsetTop = param.offsetTop;
      spyObj[i].offsetTopEnd = param.offsetTop + param.scrollHeight;
    });
  }
  firstSectionId = spyObj[0].node.id;

  update();
  window.addEventListener("scroll", onScroll, true);
};



/**
 * Callback for our scroll event - just
 * keeps track of the last scroll value
 */
let onScroll = () => {
  lastScrollY = window.scrollY;
  requestTick();
};

/**
 * Calls rAF if it's not already
 * been done already
 */
let requestTick = () => {
  if (!ticking) {
    requestAnimationFrame(update);
    ticking = true;
  }
};



/**
 * Our animation callback
 */
let update = () => {
  
  // isHome ? updateNav(): false ;
  updateNav();

  /* allow further rAFs to be called */
  ticking = false;

  
};














/**
 * updateNav
 */
let updateNav = () => {
  spyObj.forEach((el) => {
    /**
     *  adjust offset comparator if
     *  - Kunden -> offsetTopEnd
     *  - Kontakt -> offsetTop
     */
    const kontaktOffset = Math.floor(window.innerHeight * 0.7);

    let thisOffsetTop =
      el.node.id === "kontakt"
        ? el.offsetTop - kontaktOffset
        : el.offsetTop - window.innerHeight / 3;

    let thisOffsetEnd =
      el.node.id === "kunden" || el.node.id === "Datenschutz" || el.node.id === "Imprint"
        ? el.offsetTopEnd - kontaktOffset
        : el.offsetTopEnd - window.innerHeight / 3;

    

    /**
     * compare
     */
    // console.log(`${el.node.id.padEnd(20,' ')} top: ${Math.floor(thisOffsetTop)} < ${lastScrollY} < end: ${Math.floor(thisOffsetEnd)}/${el.offsetTopEnd - Math.floor(window.innerHeight / 3)}`);

    if (thisOffsetTop < lastScrollY && lastScrollY < thisOffsetEnd) {
      // console.log(el.links);
      if (el.linkState === true) {
        return;
      }
      // linkObj.forEach((links) => link.classList.remove("active"));
      // el.links.classList.add("active");
      el.links.forEach( (el) => {
        el.classList.add("active");
      })
      el.linkState = true;

      
 
      updateHistory();
      /* update history */
      // history.pushState(null, null, `${path}#${el.node.id}`)

      // console.log('+ ' + el.node.id )
    } else {

      if (el.linkState === false) {
        return;
      }
      el.links.forEach( (el) => {
        el.classList.remove("active");
      })
      el.linkState = false;

      updateHistory();
      // console.log('- ' + el.node.id )
    }
  });
};






let updateHistory = () => {
  const current = spyObj.find((obj) => obj.linkState === true);
  if (current) {
    history.pushState(null, null, `${path}#${current.node.id}`);
  } else {
    history.pushState(null, null, `${path}`);
  }
};


